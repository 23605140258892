<template>
	<v-footer class="py-16" color="light-blue darken-4" dark padless>
		<v-container class="pb-16">
			<v-row>
				<v-col class="d-flex justify-center" cols="12">
					<router-link to="/">
						<v-img
							:src="require('@/assets/iso.png')"
							alt="Jobs Bro"
							contain
							height="100"
							max-width="100%"
							width="100"
						/>
					</router-link>
				</v-col>

				<v-col class="d-flex flex-wrap justify-center" cols="12">
					<v-btn to="/policy" plain small> Privacy Policy </v-btn>

					<v-btn to="/contact" plain small> Contact Us </v-btn>

					<v-btn to="/policy#ca" plain small>
						CA - Do Not Sell My Personal Information
					</v-btn>
				</v-col>

				<v-col>
					<div class="text-caption text-center">
						© {{ new Date().getFullYear() }} Jobs Bro.
						<strong>All rights reserved</strong>.
					</div>
				</v-col>
			</v-row>
		</v-container>
	</v-footer>
</template>

<script>
export default {
	name: 'Footer',
	data: () => ({
		//
	}),
};
</script>
